<template>
  <div>
    <b-row>
      <b-col>
        <b-overlay
          :show="loading"
          rounded="sm"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
          <div
            class="cursor-pointer"
            @click="$router.push('/app/catalogs')"
          >
            <statistic-card
              :statistic="counters.catalogs"
              icon="BookIcon"
              :statistic-title="$t('Kataloglarım')"
              color="primary"
            />
          </div>
        </b-overlay>
      </b-col>
      <b-col>
        <b-overlay
          :show="loading"
          rounded="sm"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
          <div
            class="cursor-pointer"
            @click="$router.push('/app/offers')"
          >
            <statistic-card
              :statistic="counters.offers"
              icon="FileTextIcon"
              :statistic-title="$t('Tekliflerim')"
              color="success"
            />
          </div>
        </b-overlay>
      </b-col>
      <b-col>
        <b-overlay
          :show="loading"
          rounded="sm"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
          <div
            class="cursor-pointer"
            @click="$router.push('/app/messages')"
          >
            <statistic-card
              :statistic="counters.messages"
              icon="MessageSquareIcon"
              :statistic-title="$t('Mesajlarım')"
              color="info"
            />
          </div>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCol, BRow, BOverlay,
} from 'bootstrap-vue'
import StatisticCard from '@/views/Dashboard/components/StatisticCard.vue'

export default {
  name: 'Client',
  components: {
    BOverlay,
    BRow,
    BCol,
    StatisticCard,
  },
  data() {
    return {
      variant: 'transparent',
      opacity: 0.90,
      blur: '2px',
    }
  },
  computed: {
    counters() {
      return this.$store.getters['dashboard/counters']
    },
    loading() {
      return this.$store.getters['dashboard/loading']
    },
  },
  created() {
    this.getDashboard()
  },
  methods: {
    getDashboard() {
      this.$store.dispatch('dashboard/getData')
    },
  },
}
</script>
