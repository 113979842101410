<template>
  <div>
    <client-dashboard />
  </div>
</template>

<script>
import ClientDashboard from '@/views/Dashboard/Client.vue'

export default {
  components: {
    ClientDashboard,
  },
}
</script>
